import React from "react";
import { useForm, ValidationError } from "@formspree/react";

const Form = () => {
  const [state, handleSubmit] = useForm("123456");
  if (state.succeeded) {
    return (
      <p>
        Stay informed 👇
        <ul>
          <li>
            <a href="https://www.x.com/ShipMoreio">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShD3kRCTV3tCO1jfv72BGDe2LBbC08O8Hpc6XLJMQYsR0OqjJbTxdv_ccsMrPS08xu-L4&usqp=CAU"
                alt="X Logo"
                class="x-icon"
              />
            </a>
          </li>
          <li>
            <a href="https://t.me/ShipMore">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/512px-Telegram_logo.svg.png?20220101141644"
                alt="Telegram Logo"
                class="telegram-icon"
              />
            </a>
          </li>
        </ul>
      </p>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">Email Address</label>
      <input id="email" className="handleInput" type="email" name="email" />
      <ValidationError
        prefix="Email"
        className="emailInput"
        field="email"
        errors={state.errors}
      />
      <label htmlFor="email">handle</label>

      <textarea id="handle" name="handle" className="" />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button type="submit" className="button-44" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
};

export default Form;
