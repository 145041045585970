import "./App.css";
import Form from "./Form";
const App = () => {
  return (
    <div className="App">
      <h1 className="head">
        Ship More. <div className="Emoji">🚢</div>
      </h1>
      <h3 className="hero">2024 is the year to ship more.</h3>
      <h5 className="cta">
        Join for FREE and reserve your{" "}
        <span className="App-handle">@handle</span>
      </h5>
      <Form />
    </div>
  );
};

export default App;
